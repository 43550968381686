import { OSEID } from "@/constants/get_params";

import { DesignModeProviderInterface } from "../DesignModeProvider/DesignModeProviderInterface";
import LocationControllerInterface from "../LocationController/LocationControllerInterfce";
import DesignModeQueryParamsProviderInterface from "./DesignModeQueryParamsProviderInterface";

class DesignModeQueryParamsProvider
    implements DesignModeQueryParamsProviderInterface
{
    public readonly queryParams: Record<string, string> = {};

    constructor(
        designModeProvider: DesignModeProviderInterface,
        locationController: LocationControllerInterface
    ) {
        if (designModeProvider.isDesignMode()) {
            const urlParams = Object.fromEntries(
                locationController.getSearchParams().entries()
            );
            if (urlParams[OSEID]) {
                this.queryParams[OSEID] = urlParams[OSEID];
            }
        }
    }
}

export default DesignModeQueryParamsProvider;
