import { globalLoggerToken } from "@/tokens";
import { container, instanceCachingFactory, singleton } from "tsyringe";

import { ModuleInterface } from "@interfaces/ModuleInterface";

import AppsStatusesProvider from "./AppsStatusesProvider/AppsStatusesProvider";
import { DesignModeProviderInterface } from "./DesignModeProvider/DesignModeProviderInterface";
import { FromShopifyObjectDesignModeProvider } from "./DesignModeProvider/FromShopifyObjectDesignModeProvider";
import DesignModeQueryParamsProvider from "./DesignModeQueryParamsProvider/DesignModeQueryParamsProvider";
import DesignModeQueryParamsProviderInterface from "./DesignModeQueryParamsProvider/DesignModeQueryParamsProviderInterface";
import {
    locationControllerToken,
    referrerProviderToken,
    shopifyUrlUtilsToken,
} from "./di_tokens";
import LocationController from "./LocationController/LocationController";
import LocationControllerInterface from "./LocationController/LocationControllerInterfce";
import ReferrerProvider, {
    ReferrerProviderInterface,
} from "./ReferrerProvider";
import { ShopifyUrlUtils } from "./ShopifyUrlUtils/ShopifyUrlUtils";

@singleton()
export class CommonModule
    implements
        ModuleInterface<{
            locationController: LocationControllerInterface;
            shopifyUrlUtils: ShopifyUrlUtils;
            referrerProvider: ReferrerProviderInterface;
            appsStatusesProvider: AppsStatusesProvider;
            designModeProvider: DesignModeProviderInterface;
        }>
{
    exports: {
        locationController: LocationControllerInterface;
        shopifyUrlUtils: ShopifyUrlUtils;
        referrerProvider: ReferrerProviderInterface;
        appsStatusesProvider: AppsStatusesProvider;
        designModeProvider: DesignModeProviderInterface;
        designModeQueryParamsProvider: DesignModeQueryParamsProviderInterface;
    };
    public constructor() {
        const commonModuleLogger = container
            .resolve(globalLoggerToken)
            .newLogger("CommonModule");
        container.register(locationControllerToken, {
            useFactory: instanceCachingFactory(() => {
                return new LocationController(window.location);
            }),
        });
        container.register(shopifyUrlUtilsToken, {
            useFactory: instanceCachingFactory(() => {
                return new ShopifyUrlUtils(window.Shopify?.routes?.root || "/");
            }),
        });
        container.register(referrerProviderToken, {
            useFactory: instanceCachingFactory(() => {
                return new ReferrerProvider();
            }),
        });
        container.register(AppsStatusesProvider, {
            useFactory: instanceCachingFactory(() => {
                return new AppsStatusesProvider(commonModuleLogger);
            }),
        });
        const designModeProvider = new FromShopifyObjectDesignModeProvider();

        const designModeQueryParamsProvider = new DesignModeQueryParamsProvider(
            designModeProvider,
            container.resolve(locationControllerToken)
        );

        this.exports = {
            locationController: container.resolve(locationControllerToken),
            shopifyUrlUtils: container.resolve(shopifyUrlUtilsToken),
            referrerProvider: container.resolve(referrerProviderToken),
            appsStatusesProvider: container.resolve(AppsStatusesProvider),
            designModeProvider,
            designModeQueryParamsProvider,
        };
    }
    public registerProviders() {
        return {};
    }
}
